// load sentry before we import anything else
import * as Sentry from "@sentry/browser";
import { SENTRY_DSN } from './config.js';
Sentry.init({
    dsn: SENTRY_DSN,
    debug: true,
});

import { AppController } from './apps.js';

document.addEventListener('readystatechange', (event) => {
    if (document.readyState === 'complete') {
        window.appController = new AppController();
        window.appController.init();
    }
});
