// modeled on https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
let URLHashParams = function*(url) {
    if (url.hash) {
        for (let fragment of url.hash.substring(1).split('&')) {
            fragment = decodeURIComponent(fragment)
            yield fragment.split('=', 2);
        }
    }
}

export { URLHashParams };
