import { Meny } from './meny.js';
import { URLHashParams } from './utils.js';
import * as Sentry from "@sentry/browser";
import * as config from './config.js';


class AppController {
    constructor() {
        this.params = new Map(URLHashParams(new URL(window.location.href)));
        this.targetFrame = document.getElementById('targetFrame');
    }

    async init() {
        this.setUpMeny();
        await this.determineScreenId();
        this.loadApps(); // sets the first app as the home app
        this.determineHomeApp(); // do we need to override the home app
        this.goHome();
    }

    clickToCloseMenuHandler(event) {
        clearTimeout(this.trayTimeoutId);
        this.meny.close();
    }

    determineHomeApp(firstApp) {
        let overrideName = this.params.get('homeapp');
        if (this.apps.has(overrideName)) {
            this.homeApp = this.apps.get(overrideName);
        }
        // some screens alternate between two home apps
        this.secondHomeApp = this.apps.get(this.params.get('secondhomeapp'));
    }

    async determineScreenId() {
        this.screenId = this.params.get('screenid');
        if (!this.screenId) {
            try {
                let response = await fetch(config.SCREENID_URL);
                if (response.ok) {
                    response = await response.json();
                    if (response.screen_name) {
                        this.screenId = response.screen_name;
                    }
                }
            } catch (error) {
                console.log("Unable to fetch our screenid", error);
                Sentry.captureException(error);
            }
        }
    }

    goHome() {
        let destination = this.homeApp;
        if (this.secondHomeApp && (this.currentApp == this.homeApp)) {
            destination = this.secondHomeApp;
        }
        this.goToApp(destination);
    }

    goToApp(app) {
        if (this.currentApp != app) {
            this.targetFrame.src = app.url
            this.targetFrame.scrolling = app.scrollable ? 'yes' : 'no';
            this.currentApp = app;
        }
        if (this.meny.isOpen()) {
            this.meny.close();
        }
        this.resetIdleTimeout();
    }

    loadApps() {
        this.apps = new Map();
        for (let app of config.APPLICATIONS_LIST) {
            // sometimes app definitions can't be hardcoded
            if (app.appFunction && (typeof(app.appFunction) === 'function')) {
                app = app.appFunction(this.screenId);
            }

            if (app.onlyOnScreen && (app.onlyOnScreen != this.screenId)) {
                continue;
            }
            else if (app.disabled) {
                continue;
            }

            if (this.homeApp === undefined) {
                this.homeApp = app;
            }
            this.apps.set(app.name, app);
        }

        let fragment = document.createDocumentFragment();
        let inner = document.createElement('div');
        inner.classList.add('applications-tray-inner');
        fragment.appendChild(inner);

        this.apps.forEach((app) => {
            let div = document.createElement('div');
            div.classList.add('applications-tray-item');
            let anchor = document.createElement('a');
            anchor.textContent = app.name;
            anchor.addEventListener('click', (event) => {
                this.goToApp(app);
                event.preventDefault();
            });
            div.appendChild(anchor);
            inner.appendChild(div);
        });

        let tray = document.querySelector('.applications-tray');
        tray.appendChild(fragment);
    }

    resetIdleTimeout() {
        if (this.idleTimeoutId) {
            clearTimeout(this.idleTimeoutId);
        }
        this.idleTimeoutId = setTimeout(this.goHome.bind(this),
                                        config.IDLE_TIMEOUT_MILLISECONDS);
    }

    setUpMeny() {
        let contents = document.querySelector('.contents');
        let tray = document.querySelector('.applications-tray');

        this.meny = Meny.create({
            angle: 0,
            contentsElement: contents,
            height: 150,
            menuElement: tray,
            mouse: false,
            overlap: 0,
            position: 'bottom',
            transition: '0.5s',
            transitionEasing: 'ease-in-out',
        });

        this.cover = document.querySelector('.meny-cover');
        let clickToCloseMenuHandler = this.clickToCloseMenuHandler.bind(this);
        this.meny.addEventListener('open', (event) => {
            this.cover.addEventListener('click', clickToCloseMenuHandler, false);
        });
        this.meny.addEventListener('close', (event) => {
            this.cover.removeEventListener('click', clickToCloseMenuHandler, false);
        });

        let button = document.querySelector('.applications-button');
        button.addEventListener('click', (event) => {
            this.resetIdleTimeout();
            if (this.meny.isOpen()) {
                this.meny.close();
                clearTimeout(this.trayTimeoutId);
            }
            else {
                this.meny.open();
                this.trayTimeoutId = setTimeout(
                    () => { this.meny.close(); },
                    config.TRAY_TIMEOUT_MILLISECONDS, // 30s
                );
            }
            event.stopPropagation();
        });
    }
}

export { AppController };
