export const APPLICATIONS_LIST = [
    {
        appFunction: (screenId) => {
            let app = {
                name: 'Media Lab Website',
                url: 'https://gi-www.media.mit.edu/',
                scrollable: true,
            };
            if (screenId in websiteOverrides) {
                app.url += websiteOverrides[screenId];
            }
            return app;
        },
    },
    {
        name: 'List Visual Arts Center',
        url: 'https://listart.mit.edu/sites/default/files/lobby_events.jpg',
        onlyOnScreen: 'e15-100-1',
        scrollable: false,
    },
    {
        name: 'Map',
        url: '/map/',
        scrollable: false,
    },
];
export const IDLE_TIMEOUT_MILLISECONDS = 60 * 5 * 1000; // 5 minutes
export const SCREENID_URL = 'https://redirector.media.mit.edu/client/';
export const SENTRY_DSN = 'https://c49071de98ec4bb7a1b0ee745bc47a1f@sentry.media.mit.edu/15';
export const TRAY_TIMEOUT_MILLISECONDS = 30 * 1000; // 30 seconds

const websiteOverrides = {
    // no leading slash, please
    'e14-333-1': 'labs/swatch-lab/',
    'e14-348-1': 'labs/bt-lab/',
    'e14-445-1': 'labs/lego-learning-lab/',
    'e14-474-1': 'groups/personal-robots/updates/',
    'e14-526-1': 'labs/e14-526/',
    'e14-548-1': 'labs/motorola-innovation-lab/',
    'e15-344-1': 'labs/e15-344/',
    'e15-383-1': 'labs/e15-383/',
    'e15-468-1': 'labs/e15-468/',
};

export default {
    APPLICATIONS_LIST,
    IDLE_TIMEOUT_MILLISECONDS,
    SCREENID_URL,
    SENTRY_DSN,
    TRAY_TIMEOUT_MILLISECONDS,
};
